import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { navigate } from 'gatsby-link'
import SEO from '../components/seo'
import Loading from '../components/loading'

import authActions from '../state/auth/actions'
import homeActions from '../state/home/actions'

class LocaleReload extends React.Component {
  componentDidMount() {
    this.props.onResetAll()
    setTimeout(() => {
      navigate(this.props.state.tempLocale)
    }, 1000)
  }

  render() {
    return (
      <div>
        <SEO keywords={[`invitree`]} />
        <Loading />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    state: state.app,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onResetAll: homeActions.onResetAll,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocaleReload)
